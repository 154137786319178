import { message, Result, Table, Tabs, Tag } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import dateFormat from '../Content/dateFormat'
import { getTaskById, updateTask } from '../Services/task'
import Spinner from './Spinner'
import { Overview } from './Views'
import { taskPriorityColor, taskStatusColor } from '../Content/tasks'
import { associationData } from '../Content/association'
import { getTimesheetByTaskID } from '../Services/timesheet'
import { timeCategory } from '../Content/timetracker'
import { timeDuration } from '../Controllers/timeDuration'
import * as Colors from '../styles/colors'
import DeleteItem from './DeleteItem'
import DrawerComponent from './DrawerComponent'
import { TaskForm } from './Forms'
import MarkAsCompleted from './MarkAsCompleted'

const TaskViewer = ({ id, handleTaskDelete }) => {
	const { token, id: userId, role } = useSelector(state => state.userSession)
	const [data, setData] = useState(null)
	const collabArray = data && [data.assignee, data.assignor, ...(data.collaborators || []), role === 'Admin' && userId]

	const hasAccess = collabArray && collabArray.includes(userId)
	const [timesheetsData, setTimesheetsData] = useState([])
	const [editTaskDrawer, setEditTaskDrawer] = useState(false)
	const [loading, setLoading] = useState(false)

	const fetchTaskData = async () => {
		try {
			const res = await getTaskById(token, id)
			setData(res.data.data)
		} catch (error) {
			message.error('We could not fetch the information.')
		}
	}

	const fetchTimesheetByTaskId = async () => {
		try {
			const res = await getTimesheetByTaskID(token, id)
			setTimesheetsData(res.data.data)
		} catch (error) {
			setTimesheetsData([])
		}
	}

	const handleDelete = async taskId => {
		setLoading(true)
		try {
			await updateTask(token, { id: taskId, disabled: true })
			if (handleTaskDelete) {
				handleTaskDelete()
			}
			message.success('Task deleted successfully.')
		} catch (error) {
			message.error('We could not delete the task.')
		}
		setLoading(false)
	}

	const timesheetColumns = [
		{
			title: 'Description',
			key: 'description',
			dataIndex: 'description',
			render: desc => desc || '-'
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			render: (_, rec) =>
				rec.start_time && !timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? (
					dayjs.unix(rec.start_time).format(dateFormat)
				) : (
					<Tag />
				)
		},
		{
			title: 'Start',
			dataIndex: 'start_time',
			key: 'start_time',
			render: (_, rec) =>
				rec.start_time
					? dayjs
							.unix(rec.start_time)
							.format(timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? dateFormat : 'hh:mm A')
					: null
		},
		{
			title: 'End',
			key: 'end_time',
			dataIndex: 'end_time',
			render: (_, rec) =>
				rec.end_time ? (
					dayjs
						.unix(rec.end_time)
						.format(timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? dateFormat : 'hh:mm A')
				) : (
					<Tag color={Colors.GREEN}> In Progress </Tag>
				)
		},
		{
			title: 'Duration',
			key: 'duration',
			dataIndex: 'duration',
			render: (_, rec) => {
				const duration = rec.end_time
					? moment.duration(dayjs.unix(rec.end_time).diff(dayjs.unix(rec.start_time)))
					: false

				if (duration) {
					if (timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange) {
						return timeDuration(duration, 'days')
					}
					return timeDuration(duration, 'table')
				}
				return null
			}
		},
		{
			title: 'By',
			key: 'username',
			dataIndex: 'username'
		}
	]

	useEffect(() => {
		fetchTaskData()
		fetchTimesheetByTaskId()
	}, [])

	const overviewData = {
		..._.omit(data, [
			'assignor_name',
			'assignee_name',
			'association_id',
			'association_name',
			'collaborators_data',
			'activity_id',
			'deliverable_id',
			'lead_owner_name',
			'related_to',
			'reminder',
			'task_type'
		]),
		assignor: data?.assignor_name,
		assignee: data?.assignee_name,
		association: data?.association ? (
			<div className='flex items-center'>
				<Tag>{data?.association}</Tag>
				<Link to={`${associationData.filter(a => a.name === data?.association)[0].link}?id=${data?.association_id}`}>
					{data?.association_name}
				</Link>
			</div>
		) : null,
		collaborators: data?.collaborators_data?.map((a, i) => <Tag key={i}>{a.name}</Tag>),
		end_date: data?.end_date ? dayjs.unix(data?.end_date).format(dateFormat) : null,
		start_date: data?.start_date ? dayjs.unix(data?.start_date).format(dateFormat) : null
	}

	// eslint-disable-next-line no-nested-ternary
	return data ? (
		<div>
			<div className='flex justify-between items-center'>
				<div className='text-5xl font-semibold'>{data.name}</div>
				{hasAccess && (
					<div className='flex gap-2'>
						<DrawerComponent
							form={<TaskForm edit handleClose={() => setEditTaskDrawer(false)} data={data} />}
							visible={editTaskDrawer[data.id]}
							onOpen={() => setEditTaskDrawer({ [data.id]: true })}
							onClose={() => setEditTaskDrawer(false)}
							buttonTitle='Edit'
							buttonType='primary'
						/>
						<DeleteItem
							buttonType='default'
							onDelete={() => handleDelete(data.id)}
							loading={loading}
							popTitle='Are you sure to delete this task?'
							popDescription='This will delete the task from all the modules. The timesheet entries made for this task will not be deleted. This action cannot be undone.'
						>
							Delete
						</DeleteItem>
					</div>
				)}
			</div>
			<div className='grid gap-4 mt-6 text-base font-normal'>
				<div className='flex gap-8'>
					<div className='text-bell-gray w-20'>Status</div>
					<div className='flex items-center'>
						<Tag color={taskStatusColor[data.status]}>{data.status}</Tag>
						{data.status !== 'Completed' && hasAccess && (
							<MarkAsCompleted
								task_id={data.id}
								status={data.status}
								showExtendedView
								handleData={() => {
									setData(prev => ({ ...prev, status: 'Completed' }))
								}}
							/>
						)}
					</div>
				</div>
				<div className='flex gap-8'>
					<div className='text-bell-gray w-20'>Assignee</div>
					<div>{data.assignee_name}</div>
				</div>
				<div className='flex gap-8'>
					<div className='text-bell-gray w-20'>Due Date</div>
					<div>{dayjs.unix(data.end_date).format(dateFormat)}</div>
				</div>
				<div className='flex gap-8'>
					<div className='text-bell-gray w-20'>Priority</div>
					<div>
						<Tag color={taskPriorityColor[data.priority]}>{data.priority}</Tag>
					</div>
				</div>
				<div className='my-6 grid gap-1'>
					<div className='text-xl font-bold'>Description</div>
					<div>
						{data.description || <span className='text-bell-gray text-sm font-thin'>No description added.</span>}
					</div>
				</div>
			</div>
			<div>
				<Tabs
					items={[
						{
							label: 'Progress',
							key: 'progress',
							children: hasAccess ? (
								<Table columns={timesheetColumns} dataSource={timesheetsData} pagination={false} />
							) : (
								<Result
									status='warning'
									icon={<ExclamationCircleOutlined />}
									title='Not Authorized!'
									subTitle='The progress information of this task is limited to the assignee, assignor, and their collaborators.'
								/>
							)
						},
						{ label: 'Details', key: 'details', children: <Overview data={overviewData} /> }
					]}
				/>
			</div>
		</div>
	) : (
		<Spinner />
	)
}

export default TaskViewer
