import React, { useState } from 'react'
import { Button, Form, Input, message, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import loginSVG from '../../Assets/Images/login.svg'
import logo from '../../Assets/Images/paradigm_logo.png'
import { login } from '../../Store/Actions/userSession'
import { forgotPassword, loginAPI } from '../../Services/access'
import { setProfile } from '../../Store/Actions/userProfile'
import { SUB_DOMAIN, URL } from '../../config'
import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'

const Login = () => {
	const [loading, setLoading] = useState(false)
	const [forgotPasswordLoader, setForgotPasswordLoader] = useState(false)
	const history = useHistory()
	const dispatch = useDispatch()
	const [form] = Form.useForm()

	// const onFinish = async values => {
	// setLoading(true)
	// try {
	// 	await loginAPI(values)
	// const { data } = await loginAPI(values)
	// dispatch(
	// 	login({
	// 		token: data.token,
	// 		role: data.user.role,
	// 		id: data.user.id
	// 	})
	// )
	// dispatch(setProfile(data.user))
	// history.push('/')
	// 	setLoading(false)
	// } catch (error) {
	// 	message.error(error.response?.data?.message || 'Something went wrong')
	// 	setLoading(false)
	// }
	// }

	// const handleForgetPassword = async () => {
	// 	const { email } = await form.validateFields(['email'])
	// 	try {
	// 		setForgotPasswordLoader(true)
	// 		await forgotPassword({ email })
	// 		message.success(
	// 			`A reset link has been sent to ${email}. Please check Inbox or Spam folder of your mail client.`,
	// 			5
	// 		)
	// 		form.resetFields()
	// 		setForgotPasswordLoader(false)
	// 	} catch (error) {
	// 		message.error(error?.response?.data?.message || 'Something went wrong.')
	// 		setForgotPasswordLoader(false)
	// 	}
	// }

	const TEXT = SUB_DOMAIN === 'fi-paradigm' ? 'Future Industries' : 'The Bell Group'

	return (
		<div className='grid grid-cols-5 gap-10 lg:px-20 md:px-16 px-10 bg-white'>
			<div className='hidden md:grid md:col-span-2 lg:col-span-3 place-items-center'>
				<img src={loginSVG} alt='Login Illustration' />
			</div>
			<div
				className='flex flex-col justify-center items-center h-screen space-y-5 lg:col-span-2 md:col-span-3 col-span-5'>
				<div className='flex items-center space-x-10'>
					<div className='flex items-center'>
						<span>
							<img src={logo} alt='Paradigm Logo' className='w-16' />
						</span>
						<div className='flex flex-col'>
							<span className=' text-bell-text text-xl font-bold'> Paradigm </span>
							<div className=' text-bell-gray text-sm font-light '>{TEXT}</div>
						</div>
					</div>
				</div>
				<div className='font-bold text-bell-text text-3xl'> Login to your account</div>
				<div>
					<a href={`${URL}/user/login?provider=microsoft`}>
						<Button type='primary' loading={loading}>
							Login with Microsoft
						</Button>
					</a>
				</div>
				<div>
					<a href={`${URL}/user/login?provider=google`}>
						<Button type='primary' loading={loading}>
							Login with Google
						</Button>
					</a>
				</div>
				<div className='w-full'>
					{/* <Form name='control-hooks' form={form} onFinish={onFinish} {...formItemLayout}> */}
					{/* <Form.Item
							label='Email Address'
							name='email'
							rules={[
								{
									required: true,
									message: 'Please input your email address!'
								},
								{
									type: 'email',
									message: 'Please input a valid email address!'
								}
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label='Password'
							name='password'
							rules={[
								{
									required: true,
									message: 'Please input your password!'
								}
							]}
						>
							<Input.Password />
						</Form.Item> */}

					{/*{forgotPasswordLoader ? (*/}
					{/*	<div className='text-right'>*/}
					{/*		<Spin />*/}
					{/*	</div>*/}
					{/*) : (*/}
					{/*	<div className='text-right text-bell-blue'>*/}
					{/*		<div className='inline-block cursor-pointer' onClick={handleForgetPassword}>*/}
					{/*			Forgot Password?*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*)}*/}
					{/* <Form.Item {...tailFormItemLayout}>
							<Button type='primary' htmlType='submit' loading={loading}>
								Login
							</Button>
						</Form.Item> */}
					{/* </Form> */}
				</div>
			</div>
		</div>
	)
}

export default Login
