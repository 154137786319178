// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-kanban-board {
	padding: 5px;
}

.react-kanban-card-skeleton,
.react-kanban-card,
.react-kanban-card-adder-form {
	box-sizing: border-box;
	max-width: 250px;
	min-width: 250px;
}
.react-kanban-card--dragging {
	box-shadow: 2px 2px grey;
}

.react-kanban-column {
	padding: 15px;
	border-radius: 2px;
	margin: 5px;
}
.react-kanban-column input:focus {
	outline: none;
}

.react-kanban-column-header {
	padding-bottom: 10px;
	font-weight: bold;
}
.react-kanban-column-header input:focus {
	outline: none;
}
.react-kanban-column-header__button {
	color: #333333;
	background-color: #ffffff;
	border-color: #cccccc;
}
.react-kanban-column-header__button:hover,
.react-kanban-column-header__button:focus,
.react-kanban-column-header__button:active {
	background-color: #e6e6e6;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Kanban/kanbanview.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;;;CAGC,sBAAsB;CACtB,gBAAgB;CAChB,gBAAgB;AACjB;AACA;CACC,wBAAwB;AACzB;;AAEA;CACC,aAAa;CACb,kBAAkB;CAClB,WAAW;AACZ;AACA;CACC,aAAa;AACd;;AAEA;CACC,oBAAoB;CACpB,iBAAiB;AAClB;AACA;CACC,aAAa;AACd;AACA;CACC,cAAc;CACd,yBAAyB;CACzB,qBAAqB;AACtB;AACA;;;CAGC,yBAAyB;AAC1B","sourcesContent":[".react-kanban-board {\n\tpadding: 5px;\n}\n\n.react-kanban-card-skeleton,\n.react-kanban-card,\n.react-kanban-card-adder-form {\n\tbox-sizing: border-box;\n\tmax-width: 250px;\n\tmin-width: 250px;\n}\n.react-kanban-card--dragging {\n\tbox-shadow: 2px 2px grey;\n}\n\n.react-kanban-column {\n\tpadding: 15px;\n\tborder-radius: 2px;\n\tmargin: 5px;\n}\n.react-kanban-column input:focus {\n\toutline: none;\n}\n\n.react-kanban-column-header {\n\tpadding-bottom: 10px;\n\tfont-weight: bold;\n}\n.react-kanban-column-header input:focus {\n\toutline: none;\n}\n.react-kanban-column-header__button {\n\tcolor: #333333;\n\tbackground-color: #ffffff;\n\tborder-color: #cccccc;\n}\n.react-kanban-column-header__button:hover,\n.react-kanban-column-header__button:focus,\n.react-kanban-column-header__button:active {\n\tbackground-color: #e6e6e6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
