import ChildAccountView from './Details/ChildAccountView'
import ContactsView from './Details/ContactsView'
import EnquiriesView from './Details/EnquiriesView'
import LeadsView from './Details/LeadsView'
import TasksView from './Details/TasksView'
import Details from './Information/Details'
import EnquiryAttachments from './Information/EnquiryAttachments'
import Files from './Information/Files'
import LogInformation from './Information/LogInformation'
import Notes from './Information/Notes'
import Overview from './Information/Overview'
import Revisions from './Information/Revisions'
import ActivityTimeline from './Information/Timeline'
import Costing from './Stages/Enquiries/Costing'
import EnquiryStage from './Stages/Enquiries/EnquiryStage'
import Registration from './Stages/Enquiries/Registration'
import ScopeAndEvaluation from './Stages/Enquiries/ScopeAndEvaluation'
import Submission from './Stages/Enquiries/Submission'

import Closed from './Stages/Leads/Closed'
import LeadStage from './Stages/Leads/LeadStage'
import New from './Stages/Leads/New'
import Nurturing from './Stages/Leads/Nurturing'
import Working from './Stages/Leads/Working'
import Title from './Title'
import Information from './Information'
import Variations from './Information/Variations'

export {
	ChildAccountView,
	ContactsView,
	EnquiriesView,
	LeadsView,
	TasksView,
	Details,
	EnquiryAttachments,
	Files,
	LogInformation,
	Notes,
	Overview,
	Revisions,
	ActivityTimeline,
	Costing,
	EnquiryStage,
	Registration,
	ScopeAndEvaluation,
	Submission,
	Closed,
	LeadStage,
	New,
	Nurturing,
	Working,
	Title,
	Information,
	Variations
}
