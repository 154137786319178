import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { message } from 'antd'
import Spinner from '../../Components/Spinner'
import { exchangeLoginAPI } from '../../Services/access'
import { login } from '../../Store/Actions/userSession'
import { setProfile } from '../../Store/Actions/userProfile'

function useQuery() {
	const { search } = useLocation()

	return useMemo(() => new URLSearchParams(search), [search])
}

const ExchangeLogin = () => {
	const query = useQuery()
	const dispatch = useDispatch()
	const history = useHistory()

	const exchangeLoginHandler = async () => {
		if (query.get('code')) {
			const formData = {
				code: query.get('code'),
				provider: "google"
			}
			try {
				const { data } = await exchangeLoginAPI(formData)
				dispatch(
					login({
						token: data.token,
						role: data.user.role,
						id: data.user.id
					})
				)
				dispatch(setProfile(data.user))
				history.push('/')
			} catch (error) {
				message.error(error.response?.data?.message || 'Something went wrong')
			}
		}
	}

	useEffect(() => {
		exchangeLoginHandler()
	}, [])

	return (
		<div>
			<Spinner text='Logging in...' />
		</div>
	)
}

export default ExchangeLogin
