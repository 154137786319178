// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container p {
	margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
	margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
	padding: 16px;
	background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
	display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
	background: #f0f0f0;
	color: #8e8e8e;
	border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
	background: #fff;
	border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
	padding: 24px;
	overflow: hidden;
	background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
	height: 120px;
	margin-top: -8px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Views/Information/details.css"],"names":[],"mappings":"AAAA;CACC,SAAS;AACV;AACA;CACC,iBAAiB;AAClB;AACA;CACC,aAAa;CACb,gBAAgB;AACjB;AACA;CACC,aAAa;AACd;AACA;;CAEC,mBAAmB;CACnB,cAAc;CACd,yBAAyB;AAC1B;AACA;;CAEC,gBAAgB;CAChB,kBAAkB;AACnB;AACA;CACC,aAAa;CACb,gBAAgB;CAChB,mBAAmB;AACpB;AACA;CACC,aAAa;CACb,gBAAgB;AACjB","sourcesContent":[".card-container p {\n\tmargin: 0;\n}\n.card-container > .ant-tabs-card .ant-tabs-content {\n\tmargin-top: -16px;\n}\n.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {\n\tpadding: 16px;\n\tbackground: #fff;\n}\n.card-container > .ant-tabs-card > .ant-tabs-nav::before {\n\tdisplay: none;\n}\n.card-container > .ant-tabs-card .ant-tabs-tab,\n[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {\n\tbackground: #f0f0f0;\n\tcolor: #8e8e8e;\n\tborder-color: transparent;\n}\n.card-container > .ant-tabs-card .ant-tabs-tab-active,\n[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {\n\tbackground: #fff;\n\tborder-color: #fff;\n}\n#components-tabs-demo-card-top .code-box-demo {\n\tpadding: 24px;\n\toverflow: hidden;\n\tbackground: #f5f5f5;\n}\n[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {\n\theight: 120px;\n\tmargin-top: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
